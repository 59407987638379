import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import SEO from "../SEO";

const GalleryPageTemplate = ({
  heading,
  meta_title,
  meta_description,
  images,
}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const photos = images.map((img) => {
    const image = img.node.childImageSharp.fluid;
    return {
      src: image.src,
      alt: image.originalName,
      width: image.aspectRatio,
      height: 1,
    };
  });
  return (
    <div>
      <SEO title={meta_title} description={meta_description} />
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-8 is-offset-2">
                <div className="content">
                  <div>
                    <h3 className="has-text-weight-semibold is-size-2 has-text-centered">
                      {heading}
                    </h3>
                    <Gallery
                      photos={photos}
                      direction={"column"}
                      onClick={openLightbox}
                    />
                    <ModalGateway>
                      {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                          <Carousel
                            currentIndex={currentImage}
                            views={photos.map((x) => ({
                              ...x,
                              srcset: x.srcSet,
                              caption: x.title,
                            }))}
                          />
                        </Modal>
                      ) : null}
                    </ModalGateway>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

GalleryPageTemplate.propTypes = {
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  images: PropTypes.array,
};

export default GalleryPageTemplate;
