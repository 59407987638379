import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import GalleryPageTemplate from "../components/GalleryPageTemplate";
import Layout from "../components/Layout";
import en from "../i18n/en.json";
import zh from "../i18n/zh.json";
const GalleryPage = ({ data, path }) => {
  const lang = path.slice(0, 4) === "/zh/" ? zh : en;
  return (
    <Layout path={path}>
      <GalleryPageTemplate
        meta_title={lang.gallery_meta_title}
        meta_description={lang.gallery_meta_description}
        heading={lang.gallery_heading}
        description={lang.gallery_description}
        images={data.allFile.edges}
      />
    </Layout>
  );
};

GalleryPage.propTypes = {
  data: PropTypes.shape({
    allFile: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  path: PropTypes.string,
};

export default GalleryPage;

export const pageQuery = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        sourceInstanceName: { eq: "gallery" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
